body {
	background: #f3f6fc;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0.67em;
	margin-bottom: 0.67em;
}

fieldset,
legend {
	padding: 0px;
	margin: 0px;
}

#printheader {
	display: none;
	visibility: hidden;
}

#printFooter {
	display: none;
	visibility: hidden;
}

#wrap {
	margin: 0 auto;
	max-width: 1180px;
	background: #fdfeff;
	width: 100%;
}

#wrapHomepage {
	margin: 0 auto;
	max-width: 1180px;
	background: #fdfeff;
	width: 100%;
}

#wrapInside {
	background: #fdfeff;
	max-width: 1180px;
	width: 100%;
}

.bodyWrapHomepage {
	display: inline-block;
	width: 100%;
	background: url("/20150114134215/assets/images/bg-body-wrap-homepage.jpg") repeat-x top;
}

.bodyWrap {
	display: inline-block;
	width: 100%;
	background: url("/20150114134215/assets/images/bg-body-wrap.jpg") repeat-x top;
}

#content {
	width: 100%;
	float: left;
}


/****************** Tablesaw ***********************/
/****************************************************/
table.styled.tablesaw th,
table.styled.tablesaw td {
	border-left: 0;
	border-top: 0;
}


@media (max-width: 50em) {
	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0;
	}
}

@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}


/****************** Print ***********************/
/*************************************************/
#printHeader,
#printFooter {
	display: none;
}

/****************** Header ***********************/
/*************************************************/
/* Header */
#header {
	height: 114px;
	width: 100%;
	background: #005495;
	position: relative;
	z-index: 500;
}


.headerBorder {
	height: 3px;
	background: #f28a10;
}

.headerOrangeBorderLeft {
	float: left;
	background: #d77b0e;
	width: 1px;
	height: 3px;
	margin: 0px 0px 0px 38px;
}

.headerOrangeBorderRight {
	float: right;
	background: #d77b0e;
	width: 1px;
	height: 3px;
	margin: 0px 37px 0px 0px;
}

.logo {
	float: left;
}

.logo a,
.logo a:hover {
	text-decoration: none;
	border: none;
}

.headerVerticalLines {
	background: none;
	display: inline-block;
	height: 100%;
	width: 100%;
	border-top: 3px solid #f28a10;
}


.navigationContainer {
	float: right;
	padding: 0px 98px 0px 0px;
}

.login {
	width: 100%;
	text-align: right;
	padding: 18px 0px 0px 0px;
}

.login a,
.login a:hover {
	border: 0px;
	color: #688ab1;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 12px;
	text-transform: uppercase;
	line-height: 13px;
	padding: 0px 15px 0px 0px;
	letter-spacing: 1.4px;
	font-weight: 400;
}

.login a span {
	float: right;
}

.login a span.arrow {
	font-size: 22px;
	margin-top: -3px;
	margin-left: 8px;
}

.navigation {
	width: 100%;
	margin: 54px 0px 0px 0px;
	height: 60px;
	position: relative;
}

.navigation ul {
	margin: 0px;
	padding: 0px;
	list-style-image: none;
	list-style-type: none;
	height: 60px;
}

.navigation ul li {
	display: inline-block;
	margin: 0px;
	padding: 0px;
	position: relative;
	margin: 0px 0px 0px 33px;
	height: 60px;
}

.navigation ul li a {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 20px;
	color: #fff;
	letter-spacing: 1px;
	border: 0px;
	height: 40px;
	display: inline-block;
	font-weight: 400;
}

.navigation ul li a:hover,
.navigation ul li a.selected {
	color: #97ccf4;
}

.navigation ul li a.hasChildren {
	background: url("/20150114134215/assets/images/bg-navigation.png") no-repeat center bottom;
}

.navigation ul li:hover ul {
	display: block;
}

.navigation ul ul {
	float: none;
	display: none;
	overflow: hidden;
	position: absolute;
	width: 193px;
	z-index: 4000;
	left: 0px;
	top: 54px;
	height: auto;
	border-top: 3px solid #f28a10;
}

.navigation ul ul li {
	margin: 0px;
	padding: 0px;
	display: block;
	width: 193px;
	font-size: 16px;
	color: #fff;
	line-height: 20px;
	height: auto;
}


.navigation ul ul li a {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	color: #fff;
	line-height: 20px;
	background: #103d5f;
	margin: 0px;
	height: auto;
	padding: 0px;
	width: 172px;
	display: inline-block;
	padding: 10px 10px 10px 10px;
	border-bottom: 1px solid #5a7991;
	float: left;
	font-weight: 400;
}

.navigation ul ul li a:hover {
	color: #fff;
	background: #234c6c;
}

.navigation ul ul li:last-child a {
	border-bottom: none;
}

.navigation ul ul li a span {
	padding: 0px 0px 0px 0px;
	float: left;
}

.navigation ul ul li a span.arrow {
	color: #456882;
	font-size: 28px;
	margin-top: -3px;
	padding-right: 10px;
}

#insideHeaderBanner {
	width: 100%;
	max-width: 1180px;
	height: 249px;
	position: relative;
	margin-top: -114px;
}

#insideHeader img {
	vertical-align: top;
	display: inline-block;
	margin-top: -5px;
}

#insideHeaderBanner img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media (max-width: 1150px) {
	.navigationContainer {
		padding: 0px 8px 0px 0px;
	}

	.navigation ul li {
		margin: 0px 0px 0px 23px;
	}

	.navigation ul li a {
		font-size: 18px;
	}

	.navigation ul li:last-child ul {
		left: -100px;
	}
}

@media (max-width: 991px) {
	.logo {
		height: 114px;
		float: none;
		width: calc(100% - 20%);
	}

	.logo a {
		line-height: 109px;
	}

	.logo img {
		max-width: calc(100% - 12px);
	}

	.navigationContainer {
		float: right;
		padding: 31px 0px 0px 0px;
		margin-top: -124px;
	}

	.navigation {
		display: none;
	}

	#insideHeaderBanner {
		height: 200px;
	}

	.login {
		padding: 0px;
		background: #005495;
		height: 36px;
	}

	.login a,
	.login a:hover {
		line-height: 12px;
		display: inline-block;
		padding-top: 12px;
		color: #fff;
	}
}

@media (max-width: 601px) {
	.logo {
		margin-left: 1.5vw;
	}
}

/****************** Mobile navigation ****************/
/*****************************************************/
.mobile-menu-button {
	display: none;
	height: 30px;
	width: 30px;
	background-image: url("/20150114134215/assets/images/menu.svg");
	background-repeat: no-repeat;
	background-size: 30px 30px;
	cursor: pointer;
	padding: 20px;
	background-position: center;
}

.mobile-menu-button:hover,
.mm-wrapper_opened .mobile-menu-button {
	background-color: #4290d4;
	padding: 20px;
}

.mobile-navigation-menu {
	background: #005495;
}

.mobile-navigation-menu li a,
.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
	color: #fff;
}

.mm-menu .mm-listview .mm-btn_next:after,
.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
}

.mm-navbar {
	height: 45px;
	text-align: left;
}

.mm-navbar a,
.mm-navbar a:hover {
	border-bottom: 0px;
}

.mobile-navigation-menu li a.nav-link:hover,
.mobile-navigation-menu li.active a.nav-link {
	background: rgba(255, 255, 255, 0.4);
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -15px;
}

.mm-navbar__btn:first-child {
	top: 4px;
}

.mm-navbar__title {
	padding-top: 12px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth):hover {
	background: rgba(255, 255, 255, 0.4);
}

@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mobile-menu-button {
		display: block;
	}
}

/****************** Homepage ****************/
/*********************************************/
#hompageContainer {
	min-height: 400px;
	min-height: 400px;
	max-width: 1180px;
	width: 100%;
	float: left;
	position: relative;
}

.cb1 {
	float: left;
	width: 100%;
	max-width: 1180px;
	height: 505px;
	overflow: hidden;
}

.cb2.first-half {
	margin-bottom: 3.3rem;
	display: inline-block;
	width: 100%;
}

.cb1VerticalLineLeft {
	width: 1px;
	height: 505px;
	background-color: rgba(63, 79, 102, 0.5);
	margin: 0px 0px 0px 0px;
	position: absolute;
	z-index: 5000;
	left: 38px;
}

.cb1VerticalLineRight {
	width: 1px;
	height: 505px;
	background-color: rgba(63, 79, 102, 0.5);
	position: absolute;
	z-index: 5000;
	right: 37px;
	margin: 0px 0px 0px 0px;
}


.homeboxes {
	width: calc(100% - 78px);
	max-width: 1102px;
	float: left;
	padding: 0px 39px 0px 39px;
	background: #fff;
}

.homeboxes h2.cb-title,
.homeboxes h2.cb-title a {
	margin: 0px;
	padding: 0px;
	text-decoration: none;
	border: 0px;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 32px;
	line-height: 40px;
	color: #004d91;
	font-weight: 300;
}

.homeboxes h2.cb-title {
	margin-bottom: 10px;
}

.homeboxes h2.cb-title.products {
	margin-left: 34px;
}

.homeboxes h2.cb-title a:hover {
	text-decoration: none;
	border: 0px;
	color: #f3901d;
}

.homeboxesColumn1 {
	float: left;
	max-width: 351px;
	padding: 43px 53px 0px 62px;
	width: calc(43% - 115px);
}

.homeboxesColumn2 {
	float: left;
	max-width: 520px;
	padding: 45px 68px 0px 48px;
	margin: 0px 0px 45px 0px;
	width: calc(58% - 116px);
}

.cb3 {
	width: 100%;
	float: left;
	border-bottom: 1px solid #e1e2e3;
	padding: 0px 0px 35px 0px;
	margin: 0px 0px 37px 0px;
}

.cb4 {
	width: 100%;
	float: left;
}

.cb2 .cbBody {
	width: 100%;
	margin-left: 0px;
}

.cbImage {
	float: left;
	width: 200px;
}

.cbImage img {
	vertical-align: middle;
}

.cbBody {
	float: left;
	margin-left: 34px;
	width: 286px;
	margin-top: -8px;
}

.homeboxesColumn1 .cbImage {
	float: left;
	max-width: 40%;
}

.homeboxesColumn1 .cbImage img {
	vertical-align: middle;
	max-width: 100%;
	height: auto !important;
}

.homeboxesColumn1 .cbBody {
	width: auto;
	margin-left: 34px;
}

.homeboxesColumn1 .cbBody * {
	max-width: 100%;
}

.homeboxes .more {
	margin-top: 22px;
}

.homeboxes .more a {
	font-size: 14px;
	text-transform: uppercase;
	line-height: 26px;
	border: 0px;
	color: #f3901d;
	font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-weight: 700;
}

.homeboxes .more a:hover {
	color: #faa23c;
}

.homeboxes .more a span {
	font-size: 26px;
	float: left;
	margin-top: -3px;
	margin-right: 8px;
	color: #f3901d;
	font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-weight: 700;
}

.homeboxes .more a:hover span {
	color: #faa23c;
}

.homeboxes .more a span.text {
	font-size: 14px;
	text-transform: uppercase;
	line-height: 26px;
	margin-top: 0px;
}

.cb1 {
	position: relative;
}

.cb2 h1 a,
.cb2 h2 a,
.cb2 h3 a,
.cb2 h4 a,
.cb2 h5 a,
.cb2 h6 a {
	font-size: initial;
}

.cb-subtitle a,
.cb-subtitle a:hover {
	font-weight: inherit;
	line-height: inherit;
	font-family: inherit;
	font-size: inherit !important;
	color: inherit;
	border-bottom: 0px;
}

@media (max-width: 1200px) {
	.homeboxes {
		padding-left: 0px;
		padding-right: 0px;
		width: 100%;
	}

	.homeboxesColumn1 {
		padding-left: 10px;
		padding-right: 10px;
		width: calc(43% - 20px);
	}

	.homeboxesColumn2 {
		padding-left: 10px;
		padding-right: 10px;
		width: calc(58% - 20px);
	}
}

@media (max-width: 991px) {
	.homeboxesColumn1,
	.homeboxesColumn2 {
		width: calc(100% - 60px);
		float: none;
		display: inline-block;
		max-width: none;
		padding-left: 30px;
		padding-right: 30px;
	}

	.homeboxesColumn2 {
		padding-top: 35px;
	}

	.homeboxesColumn1 {
		padding-top: 15px;
	}

	.homeboxesColumn1 .cbBody {
		margin-left: 0px;
		width: 100%;
	}

	.cbImage {
		margin-right: 34px;
	}

	.cbBody {
		float: none;
		margin-left: 0px;
		width: 100%;
		margin-top: 0px;
	}

	.cb2.first-half,
	.cb2.second-half {
		float: left;
		width: 45%;
		margin-right: 5%;
		margin-bottom: 0px;
	}

	.homeboxes h2.cb-title.products {
		margin-left: 0px;
	}

	.cb3 {
		border-top: 1px solid #e1e2e3;
		padding-top: 35px;
	}

	.cb3 .cbBody,
	.cb4 .cbBody {
		float: right;
		width: calc(100% - 234px);
	}

	.homeboxes h2.cb-title {
		margin-bottom: 5px;
		margin-top: 0px;
	}

	.cb1 {
		height: 450px;
	}
}

@media (max-width: 767px) {
	.cb1 {
		height: 300px;
	}

	.cbImage {
		float: none;
		margin: 0px 0px 15px 0px;
	}

	.cb3 .cbBody,
	.cb4 .cbBody {
		float: none;
		width: 100%;
	}

	.cb2.first-half,
	.cb2.second-half {
		float: none;
		width: 100%;
		margin: 0px 0px 0px 0px;
	}

	.cb2.first-half {
		margin-bottom: 35px;
	}
}

/****************** Slideshow ******************/
/*************************************************/
#hompageContainer .button_previous,
#hompageContainer .button_previous:hover {
	position: absolute;
	z-index: 5000;
	left: 107px;
	top: 330px;
	width: 19px;
	height: 32px;
	border: 0px;
	text-decoration: none;
	background-image: url("/20150114134215/assets/images/slideshow-arrows.png");
	background-repeat: no-repeat;
	background-position: 0px 0px;
	font-size: 0px;
	cursor: pointer;
}

#hompageContainer .button_next,
#hompageContainer .button_next:hover {
	position: absolute;
	z-index: 5000;
	right: 113px;
	top: 330px;
	width: 19px;
	height: 32px;
	border: 0px;
	text-decoration: none;
	background-image: url("/20150114134215/assets/images/slideshow-arrows.png");
	background-repeat: no-repeat;
	background-position: -19px 0px;
	font-size: 0px;
	cursor: pointer;
}

#hompageContainer .controls {
	position: absolute;
	z-index: 5000;
	text-align: center;
	bottom: 23px;
	width: 100%;
}

#hompageContainer .controls div {
	margin: 0 auto;
	text-align: center;
	font-size: 0px;
	height: 10px;
	width: 10px;
	border: 0px;
	display: inline-block;
	background-image: url("/20150114134215/assets/images/slideshow-pagination.png");
	background-repeat: no-repeat;
	background-color: transparent;
	background-position: -19px 0px;
	margin-right: 8px;
	cursor: pointer;
}

#hompageContainer .controls div.active {
	background-position: 0px 0px;
}

#hompageContainer .controls div:last-child {
	margin-right: 0px;
}

.cb1 .caption {
	margin-top: -353px;
	position: absolute;
	z-index: 5000;
	width: 775px;
	left: 210px;
	text-align: center;
	margin-left: -7px;
}

.cb1 .caption,
.cb1 .caption p {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 36px;
	line-height: 42px;
	color: #fff;
	font-weight: 300;
}

.cb1 .caption p {
	margin-bottom: 5px;
}

.cb1 .caption .title {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 36px;
	line-height: 42px;
	color: #fff;
	font-weight: 700;
}

.cb1 .caption p a {
	color: #ffffff;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	text-transform: uppercase;
	background-color: rgba(255, 255, 255, 0.1);
	border: 0px;
	text-decoration: none;
	padding: 9px 45px 9px 45px;
	border-radius: 23px;
	border: 2px solid #afb7be;
	font-weight: 700;
}

.slideshow,
.w-slide,
.slide-image {
	width: 100%;
	height: 100%;
}

.slide-image {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slide-read-more {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

@media (max-width: 1200px) {
	#hompageContainer .button_next,
	#hompageContainer .button_next:hover {
		right: 15px;
	}

	#hompageContainer .button_previous,
	#hompageContainer .button_previous:hover {
		left: 15px;
	}

	.cb1 .caption {
		margin: 0px;
		position: absolute;
		width: calc(100% - 40px);
		z-index: 500;
		bottom: 0;
		left: 0;
		top: auto;
		padding: 20px;
		background-color: rgba(0, 84, 149, 0.6);
	}

	#hompageContainer .controls {
		bottom: 0;
	}
}

@media (max-width: 991px) {
	#wrapHomepage .login {
		background: none;
	}
}

/****************** Inside Page ******************/
/*************************************************/
#insideContainer {
	background: #fff;
	min-height: 700px;
	max-width: 1102px;
	float: left;
	padding: 0px 39px 0px 39px;
	display: inline-block;
	width: 100%;
}

#leftColumn {
	float: left;
	width: 190px;
	margin: 61px 64px 40px 65px;
	padding: 29px 0px 0px 0px;
	background: url("/20150114134215/assets/images/bg-left-column.jpg") repeat-x top left;
}

#leftColumn ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	list-style-image: none;
	width: 190px;
}

#leftColumn ul li {
	margin: 0px;
	padding: 0px;
	width: 190px;
}

#leftColumn ul li a {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 18px;
	line-height: 20px;
	color: #7f8f9b;
	border-bottom: 1px solid #e1e2e3;
	text-decoration: none;
	width: 190px;
	display: inline-block;
	padding: 10px 0px 8px 0px;
	margin: 0px 0px 0px 0px;
	font-weight: 400;
}

#leftColumn ul ul {
	margin-bottom: 7px;
}

#leftColumn ul li a:hover,
#leftColumn ul li a.active {
	color: #005495;
	border-bottom: 1px solid #f3901d;
}

#leftColumn ul li a span.arrow {
	font-size: 25px;
	margin-right: 4px;
}

#leftColumn ul li li a {
	background: none;
	font-size: 16px;
	border: 0px;
	padding: 10px 0px 10px 16px;
}

#leftColumn ul li li a:hover,
#leftColumn ul li li a.active {
	border-bottom: none;
	background: none;
}

#leftColumn ul li li a span.arrow {
	display: none;
}

#leftColumn ul li li li a {
	font-size: 14px;
	width: 164px;
	padding: 11px 0px 11px 26px;
}

#mainColumn {
	float: left;
	max-width: 715px;
	margin: 30px 68px 50px 0px;
	width: calc(100% - 387px);
}

#mainColumn.no-left-column {
	margin: 0 auto;
	float: none;
	margin-bottom: 50px;
}

#mainColumn.no-left-column.with-right-sidebar {
	max-width: 975px;
	width: 100%;
}

#mainColumn.no-left-column.with-right-sidebar .primaryColumn {
	max-width: 780px;
	width: calc(100% - 195px);
}

#mainColumn .primaryColumn {
	float: left;
	max-width: 520px;
	width: calc(100% - 195px);
}

#mainColumn .rightColumn {
	float: left;
	width: 180px;
	padding: 0px 0px 0px 15px;
}

.page_content_header,
.page_content_header p {
	font-size: 22px;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	color: #7f8f9b;
	line-height: 32px;
	font-weight: 400;
}

.page_content_header {
	border-bottom: 1px solid #e1e2e3;
	margin: 0px 0px 27px 0px;
	padding: 0px 0px 25px 0px;
}

.page_content_footer {
	margin: 20px 0px 15px 0px;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

td img {
	max-width: none;
}

@media (max-width: 1200px) {
	#insideContainer {
		padding: 0px;
	}
}

@media (max-width: 991px) {
	#leftColumn {
		display: none;
	}

	#mainColumn.no-left-column.with-right-sidebar .primaryColumn,
	#mainColumn .primaryColumn {
		padding: 0px;
		margin: 0px;
		width: 100%;
		float: none;
		max-width: none;
	}

	#mainColumn {
		width: calc(100% - 30px);
		padding-left: 15px;
		padding-right: 15px;
		float: none;
		max-width: none;
	}

	#mainColumn .rightColumn {
		width: 100%;
		float: none;
		max-width: none;
		border-top: 3px solid #eaebeb;
		padding: 0px;
		padding-top: 25px;
		margin-top: 20px;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.rightColumn .box {
	margin-bottom: 1.5rem;
}

.rightColumn .box h4,
.rightColumn .box h3 {
	font-size: 20px;
	line-height: 25px;
}

.rightColumn .box td {
	vertical-align: top;
	padding-bottom: 8px;
}

.rightColumn .box .contentbox_item_image {
	width: 24px;
}

.rightColumn .box.documents td:first-child {
	padding-right: 7px;
}

.rightColumn .box td:first-child img {
	margin-right: 5px;
}

.rightColumn.box.tools td:first-child img {
	margin-right: 5px;
}


/****************** Footer ***********************/
/*************************************************/
#footer {
	width: 100%;
	background: #f3f6fc;
	float: left;
}

.footerTop {
	height: 111px;
	width: 100%;
	background: #005495 url("/20150114134215/assets/images/bg-footer.jpg") repeat-x;
}

.footerBottom {
	height: 141px;
	width: 100%;
}

.footerBox1 {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	float: left;
	padding: 55px 0px 0px 107px;
	font-weight: 400;
}

.footerBox1 ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	list-style-image: none;
}

.footerBox1 ul li {
	display: inline;
	margin: 0px;
	padding: 4px 0px 0px 0px;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 13px;
	line-height: 14px;
	color: #6b98ba;
	float: left;
	font-weight: 400;
}

.footerBox1 ul li.divider {
	padding: 0px 16px 0px 16px;
}

.footerBox1 ul li a {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 13px;
	line-height: 14px;
	color: #6b98ba;
	text-decoration: none;
	border: 0px;
	font-weight: 400;
}

.footerBox1 ul li a:hover {
	text-decoration: none;
	color: #fff;
	border: 0px;
}

.footerBox2 {
	float: right;
	padding: 55px 113px 0px 0px;
}

.footerBox2 a {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif !important;
	font-size: 12px;
	line-height: 14px;
	color: #6b98ba;
	text-decoration: none;
	border: 0px;
	font-weight: 400;
}

.footerBox2 a:hover {
	text-decoration: none;
	color: #fff !important;
	border: 0px;
}

.footerBox3 {
	width: 450px;
	float: left;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 11px;
	line-height: 14px;
	color: #9eaeba;
	padding: 20px 0px 0px 107px;
	font-weight: bold;
}

.footerBox3 p {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 11px;
	line-height: 14px;
	color: #9eaeba;
	font-weight: bold;
}

.footerBox4 {
	width: 459px;
	float: right;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 11px;
	line-height: 14px;
	color: #9eaeba;
	padding: 20px 102px 0px 0px;
	font-weight: bold;
}

.footerBox4 p {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 11px;
	line-height: 14px;
	color: #9eaeba;
	font-weight: bold;
}

.footerVerticalLines {
	background: none;
	display: inline-block;
	height: 100%;
	width: 100%;
	float: left;
}

@media (max-width: 991px) {
	.footerTop {
		height: auto;
	}

	.footerVerticalLines {
		float: none;
		padding-top: 28px;
		padding-bottom: 10px;
	}

	.footerBox1,
	.footerBox2 {
		padding: 0px;
		float: none;
		width: 100%;
		display: block;
		text-align: center;
		margin: 5px 0px 10px 0px;
		line-height: 15px;
	}

	.footerBox1 ul {
		display: inline-block;
		margin: 0 auto;
	}

	.footerBox1 ul li.divider {
		display: none;
	}

	.footerBox1 ul li:first-child {
		display: block;
		width: 100%;
		padding-top: 7px;
	}

	.footerBox1 ul li {
		display: inline-block;
		float: none;
		padding: 12px 7px 0px 7px;
	}
}

/************* Inside page styles ****************/
/*************************************************/
body {
	margin: 0px;
	padding: 0px;
	font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	color: #747577;
	line-height: 26px;
	font-weight: 400;
}

p {
	font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	color: #747577;
	line-height: 26px;
	margin: 0 0 12px;
	font-weight: 400;
}

td {
	font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	color: #747577;
	font-weight: 400;
}

h1 {
	color: #005495;
	font-size: 32px;
	line-height: 40px;
	padding: 0;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-weight: normal;
	font-weight: 300;
}

h2 {
	color: #f3901d;
	font-size: 28px;
	line-height: 36px;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-weight: normal;
	margin: 0px 0px 15px 0px;
	font-weight: 400;
}

h3 {
	color: #005495;
	font-size: 24px;
	line-height: 32px;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-weight: normal;
	margin: 0px 0px 15px 0px;
	font-weight: 400;
}

h4 {
	color: #7f8f9b;
	font-size: 22px;
	line-height: 30px;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-weight: normal;
	margin: 0px 0px 15px 0px;
	font-weight: 400;
}

h5 {
	color: #7f8f9b;
	font-size: 20px;
	line-height: 28px;
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-weight: normal;
	margin: 0px 0px 15px 0px;
	font-weight: 400;
}

h6 {
	color: #7f8f9b;
	font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
	margin: 0px 0px 5px 0px;
	font-weight: 700;
	font-size: 0.67em;
}

a {
	font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	color: #005495;
	line-height: 26px;
	border-bottom: 1px solid #d7e5ef;
	text-decoration: none;
	padding-bottom: 0px;
	font-weight: 400;
}

a:hover {
	color: #f3901d;
	text-decoration: none;
	border-bottom: 1px solid #fdfeff;
}

ul,
ol {
	margin: 15px 0px 15px 15px;
	padding: 0px;
}


ul li,
ol li {
	font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	color: #747577;
	line-height: 26px;
	font-weight: 400;
}

ul {
	list-style-image: url("/20150114134215/assets/assets/images/ic-bullet.png");
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #b7e9b9;
	font-family: 'Nunito Sans', sans-serif;
	color: #000;
	font-size: 1.15rem;
	line-height: 1.65rem;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #f1d1d7;
	font-family: 'Nunito Sans', sans-serif;
	color: #000;
	font-size: 1.15rem;
	line-height: 1.65rem;
}

#message.success *,
#message.error * {
	font-family: 'Nunito Sans', sans-serif;
	color: #000;
	font-size: 1.15rem;
	line-height: 1.65rem;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	border-collapse: collapse;
	table-layout: fixed;
}

table.styled th,
table.styled td {
	border: 1px solid #ffffff;
	padding: 8px 10px 8px 10px;
}

table.styled td {
	font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	color: #747577;
	line-height: 26px;
}

table.styled thead tr th {
	background: #005495;
	font-family: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-size: 16px;
	color: #ffffff;
	line-height: 26px;
	font-weight: normal !important;
	padding: 10px 10px 10px 10px;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #f3901d;
	color: white;
	border-right: 1px solid white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: none;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

table.styled.striped tr:nth-child(even) {
	background-color: #ebebeb;
}

table.styled.striped tr:nth-child(odd) {
	background-color: #f7f7f7;
}


/* buttons */
.primary,
button[type="submit"],
.cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	background-color: #005495;
	border: none;
	color: #ffffff;
	cursor: pointer;
	font-size: 16px;
	min-height: 25px;
	line-height: 16px;
	font-weight: normal;
	margin: 0;
	padding: 8px 20px 7px 20px;
	text-decoration: none;
	text-transform: uppercase;
	border-radius: 20px;
	font-weight: bold;
}

.primary:hover,
button[type="submit"]:hover,
.cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover {
	background: #126eb6 !important;
	color: #ffffff !important;
}

.secondary,
button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	background-color: #f3901d;
	border: none;
	color: #ffffff;
	cursor: pointer;
	font-size: 16px;
	min-height: 25px;
	line-height: 16px;
	font-weight: normal;
	margin: 0;
	padding: 8px 20px 7px 20px;
	text-decoration: none;
	text-transform: uppercase;
	border-radius: 20px;
	font-weight: bold;
}

.secondary:hover,
button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background: #faa23c;
	color: #ffffff;
}

.tertiary,
button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	font-family: 'Roboto Condensed', Arial, "Helvetica Neue", Helvetica, sans-serif;
	background-color: #7f8f9b;
	border: none;
	color: #ffffff;
	cursor: pointer;
	font-size: 16px;
	min-height: 25px;
	line-height: 16px;
	font-weight: normal;
	margin: 0;
	padding: 8px 20px 7px 20px;
	text-decoration: none;
	text-transform: uppercase;
	border-radius: 20px;
	font-weight: bold;
}

.tertiary:hover,
button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background: #94a6b4;
	color: #ffffff;
}

/* clear */
.clearBoth {
	clear: both;
}

/* forms */
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="file"],
select,
textarea {
	max-width: 100%;
	padding: 5px 12px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

select {
	padding: 4px 12px;
}

textarea {
	resize: vertical;
}

label {
	font-weight: 400;
}

@media (max-width: 991px) {
	input[type="text"],
	input[type="tel"],
	input[type="password"],
	input[type="email"],
	input[type="search"],
	input[type="file"],
	select,
	textarea {
		width: calc(100% - 24px);
	}
}

/*blockquotes*/
blockquote p {
	margin-top: 0;
	margin-bottom: 0;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
}


/******************** Modules **********************/
/***************************************************/
/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 256px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #e9ece3;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #225e7c;
	font-size: 1.1rem;
	line-height: 1.3em;
	font-weight: 500;
	font-family: 'Open Sans', sans-serif;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: calc(100% - 26px) !important;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: calc(100% - 26px) !important;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: calc(100% - 26px) !important;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: calc(100% - 26px) !important;
	}
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_list_item .location-label-events-module {
	font-weight: 500;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

#events_module.cms_list .cms_list_item .cms_date {
	font-size: 0.85rem;
	color: #636363;
}

#events_module.cms_list .cms_divider {
	margin-bottom: 1.6rem;
	margin-top: 1.5rem;
}


@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: calc(100% - 24px) !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faq */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

/* news */
#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 0.8rem;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* photo albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow {
		width: 100% !important;
		background-color: #000000;
	}
}

/* resources */

/* testimonials */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_content a.testimonial-link,
#testimonials_module.cms_list .cms_list_item .cms_content a.testimonial-link:hover {
	border-bottom: 0px;
}

#testimonials_module.cms_list .cms_list_item .cms_author_name {
	font-weight: 500;
	font-size: 0.9rem;
}

#testimonials_module.cms_list .cms_list_item {
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

/* videos */
#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid #e9ece3;
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
}

/* submision forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
	color: #333333;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad .sigNav a {
	font-size: 0.8rem;
}

@media (max-width: 991px) {
	#submissionforms_module.cms_form input[type="tel"],
	#submissionforms_module.cms_form input[type="email"],
	#submissionforms_module.cms_form input[type="file"],
	#submissionforms_module.cms_form textarea,
	#submissionforms_module.cms_form .sigPad input {
		width: calc(100% - 24px) !important;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form input[type="text"]:not(.hasDatepicker),
	#submissionforms_module.cms_form textarea,
	#submissionforms_module.cms_form select {
		width: calc(100% - 24px) !important;
	}
}

/* posts */
.posts-collection .posts-node {
	border-top: #cccccc solid 1px;
	margin: 1.5rem 0 0rem 0;
	padding: 1rem 0px 0rem 0px;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #005495;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
	font-weight: 500;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid #e9ece3;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: calc(100% - 24px);
	max-width: calc(100% - 26px);
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.rightColumn .box.posts-blocks a {
	border-bottom: 0px;
}

.rightColumn .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.rightColumn .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.rightColumn .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.rightColumn .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.rightColumn .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.rightColumn .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 37px;
	padding: 0px 8px 0px 8px;
	width: calc(100% - 60px);
}

.rightColumn .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 10px 12px 8px !important;
}

@media (max-width: 991px) {
	#posts_module.form .form_item .ui-form-input input {
		width: calc(100% - 26px);
		max-width: calc(100% - 26px);
	}

	#posts_module.form .form_item .ui-form-input,
	#posts_module.form .form_item .ui-form-note,
	#posts_module.form .form_item table {
		width: 100%;
	}
}

/* rss */
#feeds_module.cms_list .cms_title h3,
#feeds_module.cms_list .cms_date h4 {
	margin-top: 0px;
}

#feeds_module.cms_list .abstract {
	padding: 0px;
}

#feeds_module.cms_list .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}

/****************** eCommerce ***********************/
/****************************************************/
/* listing */
#products_module .item-price {
	color: #333;
	font-weight: bold;
}

#products_module.responsive .product-sku,
#products_module.responsive .product-brand,
#products_module.responsive .product-upc,
#products_module.responsive .product-hscode,
#products_module.responsive .product-msrp,
#products_module.responsive .product-weight,
#products_module.responsive .product-dimension {
	font-size: 1rem;
	color: #333;
}

/* details view */
#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding-left: 15px;
	padding-right: 15px;
}

#products_module.responsive.cms_entity .tab-item-content .product-review {
	margin-top: 0;
	margin-bottom: 0;
	border-bottom: 1px solid #ccc;
	padding: 10px 0px;
}

#products_module.responsive.cms_entity .tab-questions .product-questions {
	border-top: 1px solid #ccc;
	padding: 15px 0px 0px;
	margin: 0px;
}

#products_module.responsive.cms_entity .tab-item-content .product-review:last-child {
	border-bottom: 0px;
}

#products_module.responsive.cms_entity .tab-questions :first-child {
	border-top: 0px;
}

#products_module.responsive.cms_entity .related-items .related-items-title,
#products_module.responsive.cms_entity .recently-viewed-items .recently-viewed-items-title {
	font-size: 1.3rem;
	color: #005495;
	padding-top: 10px;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer {
	margin: 5px 0px 0px 0px;
}

#products_module.responsive.cms_form .ui-form-field.required em,
#products_module.responsive.cms_form .required-fields-information em {
	color: #ee0000;
}

#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: normal;
}

#products_module.responsive.cms_form .ui-form-fields {
	margin-bottom: 15px;
}

#products_module.responsive .items.list-view .item-wrapper {
	width: 100%;
	border-bottom: 1px solid #cecece;
	padding-bottom: 1rem;
	margin-top: 1rem;
}

#products_module.responsive .items.list-view .item-wrapper:last-child {
	border-bottom: 0px;
}

@media (min-width: 991px) {
	#products_module.responsive.cms_entity .add_to_cart_form .quantity_row .product-quantity-label {
		width: 30%;
	}
}

@media (max-width: 991px) {
	#products_module.responsive .add_to_cart_form .cart-estimate-button {
		margin-right: 10px;
	}

	#products_module.responsive .items.list-view .item-wrapper {
		padding-bottom: 0px;
	}

	#products_module.responsive .items.list-view .item-wrapper .item .item-image {
		float: none;
		border: 0px;
		width: 100%;
		text-align: left;
		display: block;
		padding: 0px;
	}

	#products_module.responsive .items.list-view .item-wrapper .item .item-description {
		margin-left: 0px;
	}
}

/* cart */
#s-cart .s-sections {
	color: #333333;
}

#s-cart .w-col.s-summary #s-totals {
	color: #000000;
}

#s-cart .w-col.s-summary #s-buttons button {
	width: auto;
}

#s-cart .w-col.s-summary #s-estimate-shipping button {
	padding-left: 0px;
	padding-right: 0px;
}

#s-customer #s-sign-in .ui-form-input {
	width: 100%;
}

#s-customer #s-sign-in .ui-form-label em,
#s-checkout .ui-form-label em {
	color: #ee0000;
}

#s-checkout .ui-form-input {
	margin-bottom: 15px;
}

#s-checkout fieldset .ui-form-text #VerificationNumber,
#s-checkout fieldset .ui-form-text #GiftCardVerificationNumber {
	width: 100%;
}

#s-checkout .ui-form-field-billing-address {
	margin-top: 15px;
}

#s-checkout .ui-form-fieldset .ui-form-fieldset-sub-div {
	background: #efefef;
	color: #333333;
}

#s-checkout .ui-form-fieldset .ui-form-fieldset-sub-div p {
	color: #333333;
}

#s-checkout .ui-form-buttons.ui-form-field-place-order,
.form-shipping-info .ui-form-buttons,
.form-payment-info .ui-form-buttons {
	padding: 0 !important;
	text-align: center;
	margin-top: 10px;
}

@media (max-width: 991px) {
	#s-cart #s-promotions.s-collapsed #s-button {
		width: auto;
	}
}

@media (max-width: 767px) {
	#s-cart .s-row .s-quantity-field,
	#s-wishlist .s-row .s-quantity-field,
	#s-favorites .s-row .s-quantity-field {
		height: auto;
	}
}

/*************** Content Boxes **********************/
/***************************************************/
/* events */
.cb1 #events_module.homepage_contentbox {
	margin-top: 200px;
}

#events_module.homepage_contentbox .event_list {
	padding-bottom: 15px;
}

/* news */
.cb1 #news_module.homepage_contentbox {
	margin-top: 150px;
}

#news_module.homepage_contentbox .cms_title h3,
#news_module.homepage_contentbox .cms_title h3 a {
	font-size: 20px;
	line-height: 28px;
}

#news_module.homepage_contentbox .cms_title h3 {
	margin-bottom: 5px;
	margin-top: 0px;
}

#news_module.homepage_contentbox .cms_date h3,
#news_module.homepage_contentbox .cms_date h3 a {
	font-size: 18px;
	line-height: 25px;
	margin: 0px;
}

#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 15px;
}

/* posts */
.bodyWrapHomepage .cb1 .posts-collection .posts-node {
	margin-top: 150px;
}

.bodyWrapHomepage .posts-collection .posts-node {
	border-top: 0px;
	margin: 10px 0 0rem 0;
	padding: 5px 0px 0rem 0px;
}

.bodyWrapHomepage .posts-collection.homepage-contentbox.article-items {
	margin-bottom: 0px;
}

/* rss */
.cb1 #feeds_module.homepage_contentbox {
	margin-top: 150px;
}

#feeds_module.homepage_contentbox .item {
	background-position: 0px 5px;
}

/* testimonials */
.cb1 #testimonials_module_contentbox {
	margin-top: 150px;
}

#testimonials_module_contentbox .company,
#testimonials_module_contentbox .name {
	font-weight: bold;
}

/* submission forms */
#submissionforms_module.homepage_contentbox table {
	width: auto;
}

.slider-section #submissionforms_module.homepage_contentbox {
	padding: 150px 20px 20px 20px;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: calc(100% - 26px);
	max-width: calc(100% - 26px);
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: calc(100% - 26px) !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: calc(100% - 26px);
}

@media (min-width: 991px) {
	.slider-section #submissionforms_module.homepage_contentbox table td,
	.slider-section #submissionforms_module.homepage_contentbox table a,
	.slider-section #submissionforms_module.homepage_contentbox table a:hover,
	.slider-section #submissionforms_module.homepage_contentbox .cms_hint,
	.slider-section #submissionforms_module.homepage_contentbox .cms_date_hint {
		color: #fff;
		font-weight: normal;
	}
}


/* Locations */
#locations_module.list .location_types_title {
	padding-bottom: 0px;
	margin: -20px 0px 15px 0px;
	border-bottom: 1px solid #000000;
}

#locations_module_search_contentbox.contentbox_item .item_field select#MilesRadius {
	width: auto;
	margin-right: 10px;
}

#locations_module_search_contentbox.contentbox_item .item_field input#PostalCode {
	width: calc(35% - 10px);
	padding: 4px 12px;
}

#locations_module_search_contentbox.contentbox_item .item_field input#Keywords {
	width: 100%;
	box-sizing: border-box;
}

#locations_module.list .locations_list_title {
	margin: 11px 0px 15px 0px;
	border-bottom: 1px solid #000000;
}


/********************* Date Picker *****************/
/***************************************************/
.ui-datepicker * {
	font-size: 0.9rem;
}

.ui-datepicker a {
	border-bottom: 0px;
}

/********************* UI tags **********************/
/***************************************************/
.ui-form-legend {
	background: none;
	padding: 0px;
	text-transform: none;
}

.ui-form-fields {
	padding: 0px;
}

.ui-form-field {
	float: none;
	position: static;
}

.ui-form-label {
	height: auto;
	left: 0;
	position: static;
	text-align: left;
	top: 0;
	width: 100%;
}

.ui-form-select > select,
.ui-form-textarea > textarea,
.ui-form-text > input[ type="password" ],
.ui-form-text > input[ type="text" ] {
	max-width: calc(100% - 24px);
}


/****************** Pagination ***********************/
/****************************************************/
.fly-out-menu-container .fly-out-menu-button {
	line-height: 1rem;
	border: 1px solid #cccccc;
}

.responsive-iframe {
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
}

.responsive-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}
